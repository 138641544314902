import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
  Colors,
  MinPageHeight,
  Container,
  BlogArchiveList,
} from '../styles/globalStyles';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import _groupBy from 'lodash.groupby';
import ExternalLinkIcon from '../images/icons/external-link.svg';

const BlogListing = styled.li`
  display: grid;
  grid-template-columns: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 80% 20%;
    margin-bottom: 0;
  }

  a {
    color: ${Colors.red};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .blog-post-date {
    display: none;
    color: ${Colors.grayDark};
    @media (min-width: 768px) {
      display: block;
      text-align: right;
    }
  }

  .externalIcon {
    margin-left: 10px;
  }
`;

const externalPublications = [
  {
    title: `How to reserve engineering capacity and deliver projects on time`,
    type: `external`,
    date: `Sep 04, 2024`,
    url: `https://leaddev.com/management/how-reserve-engineering-capacity-and-deliver-projects-time/`,
  },
  {
    title: `Is a designer a storyteller?`,
    type: `external`,
    date: `Oct 07, 2015`,
    url: `https://www.sitepoint.com/is-a-designer-a-storyteller/`,
  },
  {
    title: `The absolute beginner’s guide to user testing`,
    type: `external`,
    date: `Sep 08, 2015`,
    url: `https://www.sitepoint.com/the-absolute-beginners-guide-to-user-testing/`,
  },
  {
    title: `Designing effective screens`,
    type: `external`,
    date: `Jul 24, 2015`,
    url: `https://www.knockmedia.com/designing-effective-screens/`,
  },
  {
    title: `UI Patterns – What’s in a (User)name?`,
    type: `external`,
    date: `Feb 10, 2015`,
    url: `https://www.sitepoint.com/ui-patterns-whats-username/`,
  },
  {
    title: `15 Steps To Better Usability (and Happier Users)`,
    type: `external`,
    date: `Jan 19, 2015`,
    url: `https://www.sitepoint.com/making-happy-users-better-usability/`,
  },
  {
    title: `Component-Driven CSS Frameworks`,
    type: `external`,
    date: `Jan 1, 2015`,
    url: `https://www.sitepoint.com/component-driven-css-frameworks/`,
  },
  {
    title: `Using Source Maps to Debug Sass in Chrome`,
    type: `external`,
    date: `Sep 24, 2014`,
    url: `http://www.sitepoint.com/using-source-maps-debug-sass-chrome/`,
  },
  {
    title: `A Look at Different Sass Architectures`,
    type: `external`,
    date: `Aug 19, 2014`,
    url: `https://www.sitepoint.com/look-different-sass-architectures/`,
  },
];

const normalizeData = (maybeExternalPosts) => {
  return maybeExternalPosts
    .map((post) => {
      return {
        ...post,
        title: post?.title ?? post?.node?.frontmatter?.title,
        url: post?.url ?? `/blog/${post.node.frontmatter.slug}`,
        type: post?.type ?? 'internal',
        date: new Date(post?.node?.frontmatter?.date ?? post.date),
      };
    })
    .sort((postA, postB) => postB.date - postA.date);
};

const groupPosts = (posts) => {
  return _groupBy(posts, (i) => i.date.getFullYear());
};

function BlogArchive({ posts }) {
  const sortedPosts = groupPosts(
    normalizeData([...posts, ...externalPublications])
  );

  return (
    <Layout>
      <Seo
        title="Blog - Vinay Raghu - Software Developer"
        keywords={[`gatsby`, `application`, `react`]}
      />
      <MinPageHeight>
        <Container>
          <h1>Blog posts</h1>
          {Object.keys(sortedPosts)
            .reverse()
            .map((yearOfPosts, index) => {
              return (
                <section key={index}>
                  <h3>{yearOfPosts}</h3>
                  <BlogArchiveList>
                    {sortedPosts[yearOfPosts].map((post, index) => (
                      <BlogListing key={index}>
                        <div>
                          <Link to={post.url}>
                            {post.title}
                            {post?.type === 'external' ? (
                              <img
                                src={ExternalLinkIcon}
                                alt="External URL"
                                className="externalIcon"
                              />
                            ) : null}
                          </Link>
                        </div>
                        {/* this normalizes the date between all the data sets */}
                        <div className="blog-post-date">
                          {formatDistanceToNow(post.date)} ago
                        </div>
                      </BlogListing>
                    ))}
                  </BlogArchiveList>
                </section>
              );
            })}
        </Container>
      </MinPageHeight>
    </Layout>
  );
}

const BlogDataQuery = () => (
  <StaticQuery
    query={graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/blog/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "MMM DD, YYYY")
                tags
                slug
              }
              tableOfContents
              timeToRead
              wordCount {
                paragraphs
                sentences
                words
              }
              body
            }
          }
        }
      }
    `}
    render={(data) => <BlogArchive posts={data.allMdx.edges} />}
  />
);

export default BlogDataQuery;
